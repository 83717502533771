// src/TxtViewer.js
import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';

const TxtViewer = ({ url }) => {
    const [text, setText] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [matches, setMatches] = useState([]);
    const [currentMatchIndex, setCurrentMatchIndex] = useState(-1);
    const matchRefs = useRef([]);

    useEffect(() => {
        axios.get(url)
            .then(response => setText(response.data))
            .catch(error => console.error('Error fetching the text file:', error));
    }, [url]);

    const searchKeyword = useCallback((searchTerm) => {
        if (searchTerm) {
            setSearchTerm(searchTerm)
            const regex = new RegExp(searchTerm, 'gi');
            const newMatches = [];
            let match;
            while ((match = regex.exec(text)) !== null) {
                newMatches.push(match.index);
            }
            setMatches(newMatches);
            setCurrentMatchIndex(newMatches.length > 0 ? 0 : -1);
            if(newMatches.length){
                window.ReactNativeWebView?.postMessage(`searchResult: ${newMatches.length}`);
              }else{
                window.ReactNativeWebView?.postMessage(`searchResult: 0`);
              }
            return newMatches.length;
        } else {
            setMatches([]);
            setCurrentMatchIndex(-1);
        }
    }, [text]);

    useEffect(() => {
        if (currentMatchIndex >= 0 && matchRefs.current[currentMatchIndex]) {
            matchRefs.current[currentMatchIndex].scrollIntoView({
                behavior: 'smooth',
                block: 'center',
            });
        }
    }, [currentMatchIndex]);

    const focusNext = () => {
        if (matches.length > 0) {
            setCurrentMatchIndex((currentMatchIndex + 1) % matches.length);
        }
    };

    const focusPrevious = () => {
        if (matches.length > 0) {
            setCurrentMatchIndex((currentMatchIndex - 1 + matches.length) % matches.length);
        }
    };

    const clearSearch = () => {
        setSearchTerm('')
    }

    window.searchKeyword = searchKeyword;
    window.focusNext = focusNext;
    window.focusPrevious = focusPrevious;
    window.clearSearch = clearSearch;

    const highlightText = () => {
        if (!searchTerm) return text;
        const parts = [];
        matchRefs.current = [];
        let lastIndex = 0;
        matches.forEach((matchIndex, i) => {
            parts.push(text.slice(lastIndex, matchIndex));
            const matchEnd = matchIndex + searchTerm.length;
            parts.push(
                <span ref={el => matchRefs.current[i] = el} key={i} className={ i === currentMatchIndex ? 'current-highlighted fade-in' : 'highlighted fade-out' }>
                    {text.slice(matchIndex, matchEnd)}
                </span>
            );
            lastIndex = matchEnd;
        });
        parts.push(text.slice(lastIndex));
        return parts;
    };

    return (
        <div>
            <pre style={{ whiteSpace: 'pre-wrap' }}>
                {highlightText()}
            </pre>
        </div>
    );
};

export default TxtViewer;
