import React, { forwardRef, useEffect, useState } from 'react';
import '@react-pdf-viewer/search/lib/styles/index.css';

const SearchBar = forwardRef(({ searchPluginInstance, showSearchBar, onSearch, onNextMatch, onPreviousMatch }, ref) => {
  const [searchPerformed, setSearchPerformed] = useState(false);
  const [keyword, setKeyword] = useState('');

  useEffect(() => {
    if (showSearchBar && ref && ref.current) {
      ref.current.focus();
    }
  }, [showSearchBar, ref]);

  const handleSearch = () => {
    const trimmedKeyword = keyword.trim();
    if (trimmedKeyword !== '') {
      onSearch(trimmedKeyword);
      setSearchPerformed(true); 
    } else {
      onSearch(null);
      setSearchPerformed(false); 
    }
  };

  const handleInputChange = (e) => {
    const newValue = e.target.value;
    setKeyword(newValue);
    if (newValue.trim() === '') {
      onSearch(null);
      setSearchPerformed(false); 
    }
  };

  return (
    showSearchBar && (
      <div className="search-bar">
        <div className="searchContainer">
          <input
            type="text"
            placeholder="Enter text to search"
            value={keyword}
            onChange={handleInputChange}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                handleSearch();
              }
            }}
            ref={ref}
            className={keyword === '' ? 'highlighted-off' : ''}
          />
          <button onClick={handleSearch}>Search</button>
        </div>
        {searchPerformed && (
          <div className="nextTap">
            <button onClick={onPreviousMatch}>
              &#9650; {/* Up arrow */}
            </button>
            <button onClick={onNextMatch}>
              &#9660; {/* Down arrow */}
            </button>
          </div>
        )}
      </div>
    )
  );
});

export default SearchBar;
