import React, { useState, useEffect, useRef, useCallback, memo } from 'react';
import Papa from 'papaparse';
import Highlighter from 'react-highlight-words';
import SearchBar from './searchInCSV';
import { isAndroid, isIphoneSafari } from '../App';

const TextHighlighter = ({searchTerm, value, matches, rowIndex, currentMatchIndex, colIndex}) => {
  return(
    <Highlighter
    searchWords={[searchTerm]}
    autoEscape={true}
    textToHighlight={value.toString()}
    highlightClassName={
      searchTerm === ''
        ? ''
        : matches.find(match => match?.rowIndex === rowIndex && match?.colIndex === colIndex)
          ? (matches[currentMatchIndex]?.rowIndex === rowIndex && matches[currentMatchIndex]?.colIndex === colIndex
            ? 'currentHighlighted fade-in'
            : 'highlighted fade-in')
          : ''
    }
  />
  )
}
const MemoizedTextHighlighter = React.memo(TextHighlighter);

const CsvViewer = ({ csvUrl, searchPluginInstance }) => {
  const [csvData, setCsvData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [matches, setMatches] = useState([]);
  const [currentMatchIndex, setCurrentMatchIndex] = useState(0);
  const tableRef = useRef(null);
  const searchBarRef = useRef(null);
  const isMobile = isAndroid() || isIphoneSafari();
  const [chunkSize] = useState(50);
  const loadedRows = useRef(50);
  const csvResponse = useRef([])
  const loadMoreRef = useRef(); // Reference for triggering load on scroll
  let parsedRows = [];

  const searchKeyword = useCallback((keyword, csvFromRemote) => {    
    if (keyword === null || keyword === '') {
      setSearchTerm('');
      setMatches([]);
      setCurrentMatchIndex(0);
      if (isMobile) {
        window.ReactNativeWebView?.postMessage('searchResult: []');
      }

      setTimeout(() => {
        setSearchTerm('');
        setMatches([]);
      }, 100);

      return;
    }

    if (keyword) {
      setSearchTerm(keyword);
      const newMatches = [];
      let data = csvFromRemote || csvData
      data.forEach((row, rowIndex) => {
        Object.values(row).forEach((value, colIndex) => {
          if (value.toString().toLowerCase().includes(keyword.toLowerCase())) {
            newMatches.push({ rowIndex, colIndex });
          }
        });
      });

      setMatches(newMatches);
      setCurrentMatchIndex(0);

      if (isMobile) {
        if (newMatches.length > 0) {
          window.ReactNativeWebView?.postMessage(`searchResult: ${JSON.stringify(newMatches)}`);
        } else {
          window.ReactNativeWebView?.postMessage('searchResult: 0');
        }
      }
      return newMatches.length
    }
  }, [csvData, isMobile]);

  useEffect(() => {
    fetch(csvUrl)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.text();
      })
      .then((text) => {
        if (text) {
          csvResponse.current = text
          papaparseCsv(text)
        }
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, [csvUrl]);

  const papaparseCsv = useCallback((text) => {
    Papa.parse(text, {
      header: true,
      skipEmptyLines: true,
      step: (result, parser) => {
        parsedRows.push(result.data);
        if (parsedRows.length === loadedRows.current) {
          parser.pause();          
          if(searchTerm !== ''){
            searchKeyword(searchTerm, [...csvData, ...parsedRows])
          }
          setCsvData((prev) => [...prev, ...parsedRows]);
          setLoading(false);
        }
      },
      complete: function () {
        setCsvData((prev) => [...prev, ...parsedRows]);
        setLoading(false);
      },
    });
  }, [searchTerm, csvData, searchKeyword, parsedRows])

  useEffect(() => {
    window.clearSearch = () => {
      setSearchTerm('');
      setMatches([]);
      setCurrentMatchIndex(0);
    };
  }, []);


  useEffect(() => {
    const handleKeyDown = (event) => {
      if (matches.length > 0) {
        if (event.key === 'ArrowDown') {
          event.preventDefault();
          handleNextMatch();
        } else if (event.key === 'ArrowUp') {
          event.preventDefault();
          handlePreviousMatch();
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [matches, currentMatchIndex]);

  useEffect(() => {    
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          loadedRows.current += chunkSize;
          papaparseCsv(csvResponse.current)
        }
      },
      { threshold: 1.0 }
    );
    let timeout = setTimeout(() => {
      if (loadMoreRef.current) {
        observer.observe(loadMoreRef.current);
      }
    }, 1000);
    return () => {
      observer.disconnect();
      clearTimeout(timeout)
    }
  }, [searchTerm, csvData, searchKeyword, chunkSize, papaparseCsv]);

  const scrollToMatch = useCallback((index) => {
    if (tableRef.current && matches.length > 0) {
      const match = matches[index];
      if (match) {
        const cell = tableRef.current.querySelector(
          `tbody tr:nth-child(${match.rowIndex + 1}) td:nth-child(${match.colIndex + 1})`
        );
        if (cell) {
          tableRef.current.querySelectorAll('.current-highlighted').forEach((el) => {
            el.classList.remove('current-highlighted');
          });
          cell.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
          cell.classList.add('current-highlighted');
          setTimeout(() => cell.classList.remove('current-highlighted'), 500);
        }
      }
    }        
  }, [matches]); // `matches` should be included as a dependency

  const handleNextMatch = useCallback(() => {
    const nextIndex = (currentMatchIndex + 1) % matches.length;
    setCurrentMatchIndex(nextIndex);
    scrollToMatch(nextIndex);
  }, [currentMatchIndex, matches, scrollToMatch]);

  const handlePreviousMatch = useCallback(() => {
    const prevIndex = (currentMatchIndex - 1 + matches.length) % matches.length;
    setCurrentMatchIndex(prevIndex);
    scrollToMatch(prevIndex);
  }, [currentMatchIndex, matches, scrollToMatch]);

  useEffect(() => {
    window.focusNext = handleNextMatch;
    window.focusPrevious = handlePreviousMatch;
  }, [handleNextMatch, handlePreviousMatch]);

  const handleMobileSearch = useCallback((event) => {
    const keyword = event.data?.keyword;
    searchKeyword(keyword);
  }, [searchKeyword]);

  useEffect(() => {
    // Exposing the searchKeyword function globally for mobile app
    window.searchKeyword = (keyword) => {
      return searchKeyword(keyword);
    };

    if (isMobile) {
      window.addEventListener('message', handleMobileSearch);

      return () => {
        window.removeEventListener('message', handleMobileSearch);
      };
    }
  }, [isMobile, handleMobileSearch, searchKeyword]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <>
      <div className='searchCSV-Con'>
        {!isMobile &&
          <SearchBar
            searchPluginInstance={searchPluginInstance}
            showSearchBar={true} // Always show
            onSearch={searchKeyword}
            onNextMatch={handleNextMatch}
            onPreviousMatch={handlePreviousMatch}
            ref={searchBarRef}
          />}
      </div>

      <div>
        {csvData.length > 0 ? (
          <>
            <table ref={tableRef}>
              <thead>
                <tr>
                  {Object.keys(csvData[0]).map((key) => (
                    <th key={key}>{key}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {csvData.map((row, rowIndex) => (
                  <tr key={`${row?.date}-${row?.county}-${rowIndex}`} id={`${row?.date}-${row?.county}-${rowIndex}`}>
                    {Object.values(row).map((value, colIndex) => (
                      <td key={colIndex}>
                        <MemoizedTextHighlighter
                          colIndex={colIndex}
                          currentMatchIndex={currentMatchIndex}
                          matches={matches}
                          rowIndex={rowIndex}
                          searchTerm={searchTerm}
                          value={value}
                        />
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
            {loadedRows.current <= csvData.length && (
              <div ref={loadMoreRef}>Loading more...</div>
            )}
          </>
        ) : (
          <div>No data available</div>
        )}
      </div>
    </>
  );
};

export default CsvViewer;
