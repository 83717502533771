import React, { useEffect, useRef } from 'react';

// Function to extract file name from URL
const getFileNameFromUrl = (url) => {
  try {
    const urlObject = new URL(url);
    const pathname = urlObject.pathname;
    const fileName = pathname.substring(pathname.lastIndexOf('/') + 1);
    return decodeURIComponent(fileName);
  } catch (error) {
    console.error('Error extracting file name from URL:', error);
    return 'document.pdf'; // Fallback name if extraction fails
  }
};

// Generate a unique fileId based on the filename or URL
const generateUniqueFileId = (url) => {
  const fileName = getFileNameFromUrl(url);
  const timestamp = Date.now(); // Append current timestamp to ensure uniqueness
  return `${fileName}-${timestamp}`; // Use filename with timestamp as unique ID
};

const clientIds = {
  local: '13130ddb9bcd454d87044a0c18b87642',
  staging: 'c76b4ef8389c4670bf9fbc9f67a4efb3',
  production: 'f86afc7f3fcc436ba830e6ffcae67d15',
};

// Function to determine the environment
const getEnvironment = () => {
  const hostname = window.location.hostname;
  if (hostname.includes('localhost')) {
    return 'local';
  }
  if (hostname.includes('stage.dulan18td44r5.amplifyapp.com')) {
    return 'staging';
  }
  if (hostname.includes('preview.airwave.us')) {
    return 'production';
  }
  return null; // Fallback if not matched
};

const getPageFromUrl = (url) => {
  const parsedUrl = new URL(url);
  const pageParam = parsedUrl.searchParams.get('page');
  return pageParam ? parseInt(pageParam, 10) : 1;
};

const AdobeViewer = ({ url }) => {
  const viewerRef = useRef(null);

  useEffect(() => {
    const initializeAdobeViewer = () => {
      const environment = getEnvironment();
      const adobeClientId = clientIds[environment];

      if (!adobeClientId) {
        console.error('Adobe Client ID is not available for the current environment. Viewer will not initialize.');
        return;
      }

      const fileName = getFileNameFromUrl(url);
      const fileId = generateUniqueFileId(url);
      const pageNumber = getPageFromUrl(window.location.href);

      const adobeDCView = new window.AdobeDC.View({
        clientId: adobeClientId,
        divId: viewerRef.current.id,
      });

      adobeDCView
        .previewFile(
          {
            content: {
              location: {
                url: url,
              },
            },
            metaData: {
              fileName: fileName,
              fileId: fileId,
            },
          },
          {
            embedMode: 'FULL_WINDOW',
            showAnnotationTools: false,
            showDownloadPDF: false,
            showPrintPDF: false,
            showLeftHandPanel: false,
            showPageControls: true,
            enableFormFilling: false,
            showToolbar: true,
            defaultViewMode: 'FIT_PAGE',
          }
        )
        .then((adobeViewer) => {
          console.log('File preview initialized successfully.');

          if (adobeViewer.getAPIs) {
            adobeViewer.getAPIs().then((apis) => {
              if (typeof apis.setPageNumber === 'function') {
                apis.setPageNumber(pageNumber).catch((error) => {
                  console.error('Error navigating to page:', error);
                });
              } else if (typeof apis.gotoLocation === 'function') {
                apis.gotoLocation(pageNumber).catch((error) => {
                  console.error('Error navigating to page:', error);
                });
              }
            });
          }
        })
        .catch((error) => {
          console.error('Error initializing Adobe Viewer:', error);
        });
    };

    // Ensure the Adobe SDK script is loaded before initializing the viewer
    const adobeScriptLoaded = !!window.AdobeDC;

    if (adobeScriptLoaded) {
      initializeAdobeViewer();
    } else {
      const handleAdobeReady = () => {
    initializeAdobeViewer();
      };
      window.addEventListener('adobe_dc_view_sdk.ready', handleAdobeReady);

      return () => {
        window.removeEventListener('adobe_dc_view_sdk.ready', handleAdobeReady);
      };
    }
  }, [url]);

  return (
    <div className="adobe-viewer-container">
      <div
        id="adobe-dc-view"
        ref={viewerRef}
        className="adobe-dc-view"
      />
    </div>
  );
};

export default AdobeViewer;
