import React, { useState, useRef } from "react";
import queryString from 'query-string';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import './App.css';
// import ChevronIcon from './assets/chevron.svg';
import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation';
import { Viewer, Worker, SpecialZoomLevel } from '@react-pdf-viewer/core';
import { zoomPlugin } from '@react-pdf-viewer/zoom';
import { fullScreenPlugin } from '@react-pdf-viewer/full-screen';
import { searchPlugin } from '@react-pdf-viewer/search';
import { version as pdfjsVersion } from 'pdfjs-dist';

import '@react-pdf-viewer/page-navigation/lib/styles/index.css';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/zoom/lib/styles/index.css';
import '@react-pdf-viewer/full-screen/lib/styles/index.css';
import '@react-pdf-viewer/search/lib/styles/index.css';

import CsvViewer from "./Components/CsvViewer";
import MicrosoftViewer from "./Components/MicrosoftViewer";
import HtmlViewer from "./Components/HtmlViewer";
import TxtViewer from "./Components/TxtViewer";
// import SearchBar from "./Components/searchInPdf";
import AdobeViewer from "./Components/Adobeviewer";

export const isIphoneSafari = () => {
  const ua = window.navigator.userAgent || window.navigator.vendor || window.opera;
  const isIphone = /iPhone|iPod|iPad/i.test(ua);
  const isWebKit = /AppleWebKit/i.test(ua);
  const isInApp = isIphone && isWebKit && !/Safari/i.test(ua);
  return isInApp;
};

export const isAndroid = () => {
  const ua = window.navigator.userAgent;
  return /android/i.test(ua);
};

const App = () => {
  const [numPages, setNumPages] = useState(null);
  const [showSearchBar] = useState(true);
  const searchBarRef = useRef(null);
  const renderSearchPropsRef = useRef(null);
  const highlightCurrentMatch = useRef(null);
  const videoRef = useRef(null);
  const params = queryString.parse(window.location.search);
  const page = params.page && !isNaN(params.page) ? Number(params.page) : 1;
  let modifiedUrl = params.url ? decodeURIComponent(params.url) : null;
  
  // Handle missing URL
  if (!modifiedUrl) {
    console.error("Error: URL parameter is missing or invalid in the query string.");
    return <div>Error: No valid URL provided. Please include a 'url' parameter in the query string.</div>;
  }
  let urlObject;
  try {
    urlObject = new URL(modifiedUrl);
  } catch (e) {
    console.error("Error: Invalid URL format provided:", modifiedUrl);
    return <div>Error: Invalid URL format provided. Please ensure the URL is correctly formatted.</div>;
  }
  const pathname = urlObject.pathname;
  let fileExtension = pathname.split('.').pop().toLowerCase();
  const documentExtensions = ['doc', 'docx', 'ppt', 'pptx'];
  const videoExtensions = ['mp4', 'webm', 'avi', 'mov', 'quicktime', 'wmv', 'video/x-msvideo', 'video/quicktime'];
  const imageExtensions = ['jpg', 'jpeg', 'png'];

  const pageNavigationPluginInstance = pageNavigationPlugin();
  const zoomPluginInstance = zoomPlugin();
  const fullScreenPluginInstance = fullScreenPlugin();
  const searchPluginInstance = searchPlugin();

  const onDocumentLoadSuccess = (e) => {
    setNumPages(e.doc.numPages);
  };

  const isMobile = isAndroid() || isIphoneSafari();

  const handlePageChange = (e) => {
    const pageNo = document.getElementById('pageNumber');
    if (pageNo) pageNo.innerText = e.currentPage + 1;
  };

  const searchKeyword = async (value) => {
    let numberOfMatches = 0;
    if (value?.length) {
      renderSearchPropsRef?.current?.setKeyword(value);
      await renderSearchPropsRef.current.search().then(resultCount => {
        numberOfMatches = resultCount;
        highlightCurrentMatch.current = resultCount[0];
        window.ReactNativeWebView?.postMessage(`searchResult: ${resultCount?.length}`);
      });
    } else {
      numberOfMatches = 0;
      renderSearchPropsRef?.current?.clearKeyword();
      window.ReactNativeWebView?.postMessage(`searchResult: 0`);
    }
    return numberOfMatches?.length;
  };

  const clearSearch = () => {
    renderSearchPropsRef?.current?.clearKeyword();
  };

  const focusNext = async () => {
    if (renderSearchPropsRef?.current?.currentMatch < renderSearchPropsRef?.current?.numberOfMatches) {
      const nextMatch = renderSearchPropsRef?.current?.currentMatch + 1;
      const res = await renderSearchPropsRef.current.jumpToNextMatch(nextMatch);
      highlightCurrentMatch.current = res;
      return res;
    }
  };

  const focusPrevious = async () => {
    if (renderSearchPropsRef?.current?.currentMatch > 0) {
      const prevMatch = renderSearchPropsRef?.current?.currentMatch - 1;
      const res = await renderSearchPropsRef.current.jumpToPreviousMatch(prevMatch);
      highlightCurrentMatch.current = res;
      return res;
    }
  };

  window.searchKeyword = searchKeyword;
  window.focusNext = focusNext;
  window.focusPrevious = focusPrevious;
  window.clearSearch = clearSearch;

  const pageLayout = {
    buildPageStyles: () => ({
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
    }),
    transformSize: ({ size }) => ({
      height: size.height,
      width: size.width,
    }),
  };

  const renderViewer = () => {
    if (fileExtension === 'pdf') {
      return (
        <>
          {isMobile && (
            <>
              <searchPluginInstance.Search>
                {(renderSearchProps) => {
                  renderSearchPropsRef.current = renderSearchProps;
                  return (<></>);
                }}
              </searchPluginInstance.Search>
            </>
          )}
          <Worker workerUrl={`https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsVersion}/pdf.worker.min.js`}>
            {isMobile ? (
              <div>
                <div className="document-media-container">
                  <Viewer
                    plugins={[
                      pageNavigationPluginInstance,
                      zoomPluginInstance,
                      fullScreenPluginInstance,
                      searchPluginInstance,
                    ]}
                    fileUrl={modifiedUrl}
                    defaultScale={window.outerWidth < 1024 ? SpecialZoomLevel.PageFit : SpecialZoomLevel.ActualSize}
                    initialPage={page - 1}
                    onPageChange={handlePageChange}
                    onDocumentLoad={onDocumentLoadSuccess}
                    pageLayout={pageLayout}
                  />
                </div>
                {/* {!isMobile &&
                <div className="menu-item">
                  <button onClick={pageNavigationPluginInstance.jumpToPreviousPage}>
                    <img src={ChevronIcon} alt="Chevron" /> Prev
                  </button>
                  <SearchBar
                    ref={searchBarRef}
                    showSearchBar={showSearchBar}
                    searchPluginInstance={searchPluginInstance}
                  />
                  <p>
                    Page <span id="pageNumber">{page}</span> of {numPages}
                  </p>
                  <button onClick={pageNavigationPluginInstance.jumpToNextPage} className="next">
                    Next
                    <img src={ChevronIcon} alt="Chevron" />
                  </button>
                </div>} */}
              </div>
            ) : (
              <AdobeViewer url={modifiedUrl} />
            )}
          </Worker>
        </>
      );
    } else if (videoExtensions.includes(fileExtension)) {
      let videoType = `video/${fileExtension}`;
      if (fileExtension === 'mov') {
        videoType = 'video/quicktime';
      }
      return (
        <div className="page">
          <video className="images-and-video" ref={videoRef} controls playsInline width="90%" height="auto">
            <source src={decodeURI(modifiedUrl)} type={videoType} />
            Your browser does not support the video tag.
          </video>
        </div>
      );
    } else if (documentExtensions.includes(fileExtension)) {
      return (
        <div className="document-media-container">
          <MicrosoftViewer modifiedUrl={modifiedUrl} page={page} />
        </div>
      );
    } else if (imageExtensions.includes(fileExtension)) {
      return (
        <div className="document-media-container">
          <img className="images-and-video" src={decodeURI(modifiedUrl)} alt="Document" />
        </div>
      );
    } else if (['csv'].includes(fileExtension)) {
      return (
        <CsvViewer csvUrl={modifiedUrl} />
      );
    } else if (['html', 'htm'].includes(fileExtension)) {
      return (
        <HtmlViewer url={modifiedUrl} />
      );
    } else if (['txt'].includes(fileExtension)) {
      return (
        <TxtViewer modifiedUrl={modifiedUrl} />
      );
    } else {
      return <div>Error: Unsupported file format</div>;
    }
  };

  return (
    <div className="container">
      {renderViewer()}
    </div>
  );
};

export default App;
