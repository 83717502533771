const MicrosoftViewer = ({modifiedUrl, page}) => {
    return(
        <iframe src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(modifiedUrl)}&wdStartOn=${page}&action=embedview&wdAllowInteractivity=true`} 
            width="100%"
            height="100%"
            frameBorder="0"
            allowFullScreen
            sandbox="allow-scripts allow-same-origin allow-forms"
            title="docs"
        />
    )
}

export default MicrosoftViewer;